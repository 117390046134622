import React, { Component } from "react";
import ReactDOM from "react-dom";
// import PodcastPageTemplate from "../../components/podcasts/PodcastPageTemplate";
import PageTemplate from '../PageTemplate';
import PodcastHeader from "./../../components/podcasts/PodcastHeader";
import Colors from "../../assets/Colors";
import LinkButton from "../../components/podcasts/LinkButton";
import LoadingOverlay from "../../components/LoadingOverlay";
import { ClipLoader } from "react-spinners";
import dayjs from "dayjs";
import PlayerControls from "../../components/read-page/PlayerControls";
import ReadStoryClass from "./../ReadStoryPage/ReadStoryClass";
import Paragraph from "./../../components/read-page/Paragraph";
import TranslationPopover from "./../../components/TranslationPopover";
import BlurCover from "../../components/podcasts/BlurCover";
import { connect } from "react-redux";
import SideNav from "../../components/SideNav";
import MyNavbar from "../../components/MyNavbar";
import { toggleNav } from "../../redux/actions";
import { isMobile, isBrowser } from "react-device-detect";
import Socials from "../../components/Socials";
import Constants from "../../assets/Constants";
import HighlightWindow from "../../components/read-page/HighlightWindow";
var Sound = require("react-sound").default;
const navHeight = 50;

class PlayPodcastPage extends ReadStoryClass {
  componentDidMount() {
    const id = this.props.match.params.id;
    fetch(`/podcasts/${id}`, {
      method: "GET"
    })
      .then(result => result.json())
      .then(result => {
        if (result.status == "success") {
          let newArticle = result.article;
          newArticle.pages = JSON.parse(newArticle.pages);
          this.setState({ article: newArticle, loading: false });
        } else {
          alert(result.message);
          this.setState({ loading: false });
        }
      });
    window.addEventListener("scroll", this.handleScroll.bind(this));
    this.setState({ showTutorial: false });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(e) {
    if (this.state.popoverOpen) this.setState({ popoverOpen: false });
    if (this.topOfPlayerPoint) {
      let top = this.topOfPlayerPoint.getBoundingClientRect().top;
      if (top <= navHeight && !this.state.musicPlayerLocked) this.setState({ musicPlayerLocked: true });
      if (top > navHeight && this.state.musicPlayerLocked) this.setState({ musicPlayerLocked: false });
    }
  }

  getBlurHeight() {
    let { blurHeight } = this.state.article;
    // console.log('pg', this)
    if (this["paragraph" + blurHeight]) {
      // let scrollTop = window.pageYOffset;
      let pageTop = this.pageTopPoint.getBoundingClientRect().top;
      let paragraphTop = ReactDOM.findDOMNode(this["paragraph" + blurHeight]).getBoundingClientRect().top;
      let distance = paragraphTop - pageTop;
      return distance + 40;
    }
  }

  render() {
    let { title, artwork, podcastIntroText, podcastLinks, publishedOn, createdAt, blurHeight } = this.state.article;
    const { spotifyLink, rssLink, googleLink, pocketcastsLink, itunesLink, stitcherLink } = podcastLinks || {};
    const { loggedInUser } = this.props;

    let { position, duration, playStatus, musicPlayerLocked } = this.state;
    console.log(musicPlayerLocked);

    let date = dayjs(publishedOn ? publishedOn : createdAt).format("ddd MMMM D YYYY");

    let musicBoxStyle = {
      position: "fixed",
      top: navHeight,
      left: (this.props.navState === "toggled" && !isMobile) ? 250 : (loggedInUser && !isMobile ? 77 : 0),
      backgroundColor: "#fff",
      right: 0,
      width: "auto",
      zIndex: 997
    };

    return (
      <div>
        <MyNavbar />

        <PageTemplate closePopover={() => this.setState({ popoverOpen: false })}>
          <div style={styles.insideCol}>
            <div style={styles.inside}>

              {this.state.popoverOpen ? <div style={styles.popoverBackgroundButton} onClick={this.togglePopover.bind(this)} /> : null}
              <span ref={x => (this.pageTopPoint = x)} />
              { isMobile ? null : <PodcastHeader />}
              {this.state.loading ? (
                <div style={{ display: "flex", justifyContent: "center", width: "100%", paddingTop: 100 }}>
                  <ClipLoader color="grey" />
                </div>
              ) : (
                <div style={styles.page}>
                  {/* {loggedInUser ? null : <BlurCover getTop={this.getBlurHeight.bind(this)}/> } */}
                  <Sound
                    autoLoad={true}
                    onLoading={x => this.setState({ duration: x.duration })}
                    url={this.state.article.audio}
                    playStatus={this.state.playStatus}
                    // playFromPosition={0 /* in milliseconds */}
                    // onLoading={this.handleSongLoading}
                    onPlaying={x => this.handleSongPlaying(x)}
                    position={this.state.position}
                    playbackRate={this.state.playbackRate}
                    // onFinishedPlaying={this.handleSongFinishedPlaying}
                  />

                  <HighlightWindow
                    show={this.state.showHighlight}
                    item={this.state.highlightItem}
                    hide={() => this.setState({ showHighlight: false })}
                    language={this.state.article.language}
                  />
                  <TranslationPopover
                    language={this.state.article.language ? this.state.article.language.toLowerCase() : undefined}
                    open={this.state.popoverOpen}
                    popoverInfo={this.state.popoverInfo}
                    toggleSelf={this.togglePopover.bind(this)}
                    // hideSave={true}
                  />
                  <div style={{ height: 30 }} />
                  <h3 style={styles.title}>{title}</h3>
                  <div style={styles.date}>By {Constants.appName} on {date}</div>
                  <div style={styles.imageWrapper}>
                    <img src={artwork} style={styles.image} alt="Spanish Podcast Image" />
                  </div>
                  <p style={styles.text}>{podcastIntroText}</p>
                  <div style={{ height: 10 }} />
                  <h3 style={styles.title}>How to Listen:</h3>
                  <div style={styles.linkButtonRow}>
                    {spotifyLink ? <LinkButton name="Spotify" link={spotifyLink} /> : null}
                    {rssLink ? <LinkButton name="RSS" link={rssLink} /> : null}
                    {itunesLink ? <LinkButton name="iTunes" link={itunesLink} /> : null}
                    {googleLink ? <LinkButton name="Google Podcasts" link={googleLink} /> : null}
                    {stitcherLink ? <LinkButton name="Stitcher" link={stitcherLink} /> : null}
                    {pocketcastsLink ? <LinkButton name="PocketCasts" link={pocketcastsLink} /> : null}
                  </div>

                  <div ref={x => (this.topOfPlayerPoint = x)} style={{ width: 100, height: musicPlayerLocked ? 110 : 0 }} />
                  <PlayerControls
                    setRef={(name, ref) => (this[name] = ref)}
                    position={position}
                    duration={duration}
                    togglePlaybackRate={() => this.togglePlaybackRate()}
                    playbackRate={this.state.playbackRate}
                    handleSkip={x => this.handleSkip(x)}
                    handlePlayPause={() => this.handlePlayPause()}
                    playStatus={playStatus}
                    shadow="0 18px 18px 0 rgba(10, 31, 68, 0.12), 0 0 1px 0 rgba(10, 31, 68, 0.1)"
                    // ---
                    musicPlayerLocked={musicPlayerLocked}
                    musicBoxStyle={musicPlayerLocked ? musicBoxStyle : {}}
                    socials={true}
                  />
                  <h3 style={{ ...styles.title, marginTop: 35, marginBottom: 30 }}>Transcript:</h3>

                  {this.state.article
                    ? this.state.article.pages[this.state.currentPageIndex].map((item, index) => {
                        return (
                          <div class="fadeMeIn">
                            <Paragraph
                              position={this.state.position}
                              handlePlayPause={this.handlePlayPause.bind(this)}
                              playStatus={this.state.playStatus}
                              nextParagraphStartsAt={(this.state.article.pages[this.state.currentPageIndex][index+1] || {}).startsAt}
                              ref={x => (this["paragraph" + index] = x)}
                              onboardingRefSetter={() => null}
                              paragraph={item}
                              id={index + "p" + this.state.currentPageIndex}
                              handlePlayParagraph={x => this.handlePlayParagraph(x)}
                              wordClicked={(word, translation, id) => this.handleWordClicked(word, translation, id)}
                              key={index + "p" + this.state.currentPageIndex}
                              index={index}
                              highlightClick={() => this.handleHighlightClick(item)}
                              loggedInUser={this.props.loggedInUser}
                              // hideHighlightButton={true}
                              darkButtons={true}
                              blur={!loggedInUser && index >= blurHeight ? true : false}
                              hasBlurModal={!loggedInUser && index === blurHeight ? true : false}
                            />
                          </div>
                        );
                      })
                    : null}
                  <br />
                  <br />
                </div>
              )}
              <div style={styles.bottomSocialsRow}>
                <div style={{padding: 10, paddingBottom: 20}}>
                  {loggedInUser ? null : <div id="cbox-kAFslnUzW6BmxmMO"></div>}
                </div>

              {!this.state.loading && !loggedInUser ? <Socials /> : null}
              </div>
            </div>
          </div>
        </PageTemplate>
      </div>
    );
  }
}

const styles = {
  page: {
    // mostly for blur cover
    // position: 'relative'
  },
  insideCol: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  inside: {
    maxWidth: 700,
    // overflow: 'hidden',
    paddingLeft: 10,
    paddingRight: 10,
  },
  image: {
    width: "100%"
    // marginTop: -100
  },
  imageWrapper: {
    overflow: "hidden",
    maxHeight: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  date: {
    color: "#8a94a6",
    marginTop: 15,
    marginBottom: 20,
    fontSize: 14
  },
  text: {
    color: Colors.lightText,
    lineHeight: 1.75,
    marginTop: 20,
    fontSize: 17
  },
  linkButtonRow: {
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
    marginBottom: 15,
    flexWrap: "wrap"
  },
  title: {
    color: Colors.text
  },

  popoverBackgroundButton: {
    // position: 'absolute',
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 3
    // height: '100vh'
  },
  bottomSocialsRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  }
};

const mapStateToProps = state => ({
  loggedInUser: state.loggedInUser,
  navState: state.navState,
  selectedLanguage: state.selectedLanguage
});
const mapActionsToProps = { toggleNav };
export default connect(
  mapStateToProps,
  mapActionsToProps
)(PlayPodcastPage);
